import { SupportedCountry } from 'types/country.types';

import RestApiService from './';

export default {
  getSupportedCountries(this: RestApiService) {
    return this.api.get<SupportedCountry[]>(
      `/configuration/supported-countries`,
    );
  },
};
