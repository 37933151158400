import { SnackbarProvider } from 'notistack';
import React from 'react';

interface Props {
  children: React.ReactNode;
}

const NotificationProvider = ({ children }: Props) => {
  return (
    <SnackbarProvider
      maxSnack={1}
      preventDuplicate
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export default NotificationProvider;
