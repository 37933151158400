import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

import config from 'config';

export type CountryState = {
  country: string;
};

export const countryPersistConfig = {
  key: `${config.LOCAL_STORAGE_ID}.country`,
  storage,
};

const initialState: CountryState = {
  country: '',
};

const countrySlice = createSlice({
  name: 'country',
  initialState,
  reducers: {
    setCountry: (state, { payload }: PayloadAction<string>) => {
      state.country = payload;
    },
  },
});

export const { actions: countryActions } = countrySlice;

export default countrySlice.reducer;
