import { Components, Theme } from '@mui/material';

export const MuiContainer: Components<Theme>['MuiContainer'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      [theme.breakpoints.up('lg')]: {
        maxWidth: '600px',
      },
    }),
  },
};
