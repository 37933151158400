import { Components, Theme } from '@mui/material';

export const MuiDivider: Components<Theme>['MuiDivider'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: theme.palette.global03[500],
      marginTop: '24px',
      marginBottom: '24px',
    }),
  },
};
