import {
  bottomNavigationActionClasses,
  Components,
  Theme,
} from '@mui/material';

export const MuiBottomNavigationAction: Components<Theme>['MuiBottomNavigationAction'] =
  {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.neutral01[100],
      }),
      selected: ({ theme }) => ({
        color: theme.palette.global01[400],
      }),
      label: {
        [`&.${bottomNavigationActionClasses.selected}`]: {
          fontSize: '0.857rem',
        },
      },
    },
  };
