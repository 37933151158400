export type PhraseKeyProp =
  | string
  | { id: string; values: { [index: string]: string | React.ReactElement } };

export const getPhraseProps = (key: PhraseKeyProp) =>
  typeof key === 'string' ? { id: key, values: {} } : key;

export const appendCountryToUrl = (url: string, country: string) => {
  const index = url.split('/', 3).join('/').length;
  const splittedUrl = [url.slice(0, index), url.slice(index)];
  const newUrl =
    splittedUrl[0] + `/${country.toLocaleLowerCase()}` + splittedUrl[1];
  return newUrl;
};

export const aspectRatio = (w: number, h: number) => {
  return {
    aspectRatio: `${w} / ${h}`,
    '@supports not (aspect-ratio: auto)': {
      position: 'relative',
      '&:before': {
        content: '""',
        float: 'left',
        paddingTop: `calc(100% * ${h / w})`,
      },
      '&:after': {
        display: 'block',
        content: '""',
        clear: 'both',
      },
    },
  };
};
