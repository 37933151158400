import { Container } from '@mui/material';
import { ReactNode, useState } from 'react';

import { useMedia } from 'hooks';
import { User } from 'types/user.types';

import { ExpandableHeader, QRDrawer } from 'components/@header';

interface Props {
  children: ReactNode;
  user?: User;
}

const HomeContainer = ({ children, user }: Props) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { isMobile } = useMedia();

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  return (
    <>
      {isMobile && <ExpandableHeader onOpenDrawer={handleOpenDrawer} />}
      <Container>{children}</Container>
      <QRDrawer
        phoneNumber={user?.phoneNumber ?? ''}
        isOpen={openDrawer}
        onCloseDrawer={handleCloseDrawer}
      />
    </>
  );
};

export default HomeContainer;
