import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

import config from 'config';

export type OnboardingState = {
  isDialogShown: boolean;
};

export const onboardingPersistConfig = {
  key: `${config.LOCAL_STORAGE_ID}.onboarding`,
  storage,
};

const initialState: OnboardingState = {
  isDialogShown: false,
};

const onboardingSlice = createSlice({
  name: 'oboarding',
  initialState,
  reducers: {
    setIsDialogShown: (state, { payload }: PayloadAction<boolean>) => {
      state.isDialogShown = payload;
    },
  },
});

export const { actions: onboardingActions } = onboardingSlice;

export default onboardingSlice.reducer;
