import { Outlet, useLocation } from 'react-router-dom';

import { useCountry, useMedia } from 'hooks';

import { Routes } from 'pages/routes.constants';

import { MobileNavigation } from 'components/@navigation';
import { DesktopNavigation } from 'components/@navigation/DesktopNavigation';

const Layout = () => {
  const { isMobile } = useMedia();
  const { country } = useCountry();
  const location = useLocation();

  return (
    <>
      {!isMobile && country && location.pathname !== Routes.Registration && (
        <DesktopNavigation />
      )}
      <Outlet />
      {isMobile && country && location.pathname !== Routes.Registration && (
        <MobileNavigation />
      )}
    </>
  );
};

export default Layout;
