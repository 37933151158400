import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoProfile = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7242 2.5C9.79117 2.5 8.22412 4.06705 8.22412 6.00005C8.22412 7.93294 9.79117 9.5 11.7242 9.5C13.6571 9.5 15.2241 7.93295 15.2241 6.00005C15.2241 4.06704 13.6571 2.5 11.7242 2.5ZM7.22412 6.00005C7.22412 3.51477 9.23889 1.5 11.7242 1.5C14.2094 1.5 16.2241 3.51478 16.2241 6.00005C16.2241 8.48523 14.2094 10.5 11.7242 10.5C9.2389 10.5 7.22412 8.48524 7.22412 6.00005Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.68485 11.5044C6.73084 11.5013 6.77619 11.5 6.82025 11.5H16.9393C17.1179 11.5 17.2924 11.5228 17.4593 11.5658C18.2976 11.7823 18.8689 12.5201 18.913 13.3449L19.4985 20.9617C19.5092 21.1005 19.4615 21.2376 19.3669 21.3397C19.2722 21.4419 19.1393 21.5 19 21.5H5C4.86345 21.5 4.73285 21.4442 4.63851 21.3454C4.54417 21.2467 4.49432 21.1137 4.50052 20.9773L4.8451 13.3958C4.87622 12.4093 5.66055 11.5707 6.68485 11.5044ZM6.68485 11.5044C6.68503 11.5043 6.68468 11.5044 6.68485 11.5044L6.71754 12.0033ZM6.82025 12.5C6.79571 12.5 6.77247 12.5007 6.75074 12.5022L6.74972 12.5022C6.24398 12.5349 5.85855 12.9513 5.84454 13.4294L5.8443 13.4375L5.52324 20.5H18.4601L17.9144 13.4017C17.8944 12.9981 17.6141 12.6387 17.2096 12.5342M6.82025 12.5H16.9393ZM16.9393 12.5C17.0325 12.5 17.1233 12.5119 17.2096 12.5342Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgIcoProfile);
export default Memo;
