import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoHome = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      clipRule="evenodd"
      d="m11.7041 1.59687-9.99085 6.99356c-.22622.15835-.28124.47012-.12288.69634.15835.22623.47012.28125.69634.12289l9.71329-6.7993 9.7133 6.7993c.2262.15836.538.10334.6963-.12288.1584-.22623.1034-.53799-.1229-.69635l-9.9907-6.99355c-.0259-.01901-.0534-.0353-.0819-.04881-.0683-.03243-.1415-.04813-.2141-.04807-.0726-.00006-.1457.01564-.214.04807-.0286.01351-.056.0298-.0819.0488zm-6.7041 6.90313c.27614 0 .5.22386.5.5v11.5h4.01759l.00467-5.4042c.00024-.276.22405-.4996.50004-.4996l3.9606.0003c.1327 0 .2599.0528.3537.1466.0937.0939.1464.2212.1462.3539l-.0046 5.403h4.0218v-11.5c0-.27614.2239-.5.5-.5s.5.22386.5.5v12c0 .2761-.2239.5-.5.5h-5.0223c-.1326 0-.2599-.0527-.3537-.1466-.0937-.0939-.1464-.2211-.1463-.3538l.0047-5.4031-2.9606-.0003-.0046 5.4042c-.0003.276-.2241.4996-.5.4996h-5.0172c-.27614 0-.5-.2239-.5-.5v-12c0-.27614.22386-.5.5-.5z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

const Memo = memo(SvgIcoHome);
export default Memo;
