import { Backdrop, Box, Paper, Slide } from '@mui/material';
import { ReactNode } from 'react';

import { IcoClose } from 'components/@icons';

interface Props {
  children: ReactNode;
  isOpen: boolean;
  onCloseDrawer: () => void;
}

const BottomCardDrawer = ({ children, isOpen, onCloseDrawer }: Props) => {
  return (
    <>
      <Slide in={isOpen} direction="up">
        <Box
          position="fixed"
          bottom={0}
          left={0}
          display="flex"
          alignItems="center"
          flexDirection="column"
          sx={{ zIndex: (theme) => theme.zIndex.drawer }}
        >
          <Box
            display="inline-flex"
            justifySelf="center"
            mb={2}
            onClick={onCloseDrawer}
          >
            <IcoClose width={40} height={40} />
          </Box>

          <Paper
            sx={{
              overflowY: 'auto',
              pb: 2,
              borderTopRightRadius: 16,
              borderTopLeftRadius: 16,
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
              width: '100vw',
            }}
            elevation={1}
          >
            {children}
          </Paper>
        </Box>
      </Slide>
      <Backdrop onClick={onCloseDrawer} open={isOpen} />
    </>
  );
};

export default BottomCardDrawer;
