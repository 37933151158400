import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useCountry } from 'hooks';
import { QueryKeys } from 'queries';
import { StaleTime } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { User } from 'types/user.types';

import { Routes } from 'pages/routes.constants';

const fetchUser = async (country: string) => {
  const { data: response } = await apiService.getUser(country);
  return response;
};

export const useUser = (config: UseCommonQueryOptions<User> = {}) => {
  const { country } = useCountry();
  const navigate = useNavigate();

  // Session storage set during consent because Como Api can take up to 5min to update the consent flag and we don't want to show the complete profile form again because of this
  const consentCheckedThisSession = sessionStorage.getItem(
    `consent.${country}`,
  );
  const { data, status, error, isFetching, isLoading } = useQuery(
    QueryKeys.user.byId(country),
    () => fetchUser(country),
    {
      retry: 1,
      enabled: !!country,
      // TODO: REMOVE ONERROR here should only apply to the place where the error needs to be handled not everywhere! --> can be moved to home page
      onError: (error) => {
        if (country && error.response?.status === 404) {
          navigate(Routes.Registration, { state: { showTerms: true } });
        }
      },
      onSuccess: (data) => {
        if (data?.consent !== 'yes' && consentCheckedThisSession !== '1') {
          navigate(Routes.Registration, { state: { showTerms: true } });
        }
      },
      ...config,
      cacheTime: config?.cacheTime ?? StaleTime.FIFTEEN_MIN,
      staleTime: config?.staleTime ?? StaleTime.FOREVER,
    },
  );

  return {
    data,
    status,
    isFetching,
    isLoading,
    error,
  };
};
