import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

import config from 'config';

import { formatBrowserLocale, isValidLocale } from 'services/i18n/config';

interface IntlState {
  locale: string;
}

const browserLocale = formatBrowserLocale(navigator.language);

export const intlPersistConfig = {
  key: `${config.LOCAL_STORAGE_ID}.intl`,
  storage,
};

const initialState: IntlState = {
  locale: isValidLocale(browserLocale) ? browserLocale : config.DEFAULT_LOCALE,
};

const intlSlice = createSlice({
  name: 'intl',
  initialState,
  reducers: {
    LOCALE_UPDATE: (state, { payload }: PayloadAction<IntlState['locale']>) => {
      state.locale = payload;
    },
  },
});

export const { actions: intlActions } = intlSlice;

export default intlSlice.reducer;
