import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoClose = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
    <path
      d="m20 3.33337c-9.2167 0-16.66669 7.45003-16.66669 16.66663 0 9.2167 7.44999 16.6667 16.66669 16.6667 9.2166 0 16.6666-7.45 16.6666-16.6667 0-9.2166-7.45-16.66663-16.6666-16.66663zm8.3333 22.65003-2.35 2.35-5.9833-5.9834-5.9834 5.9834-2.35-2.35 5.9834-5.9834-5.9834-5.9833 2.35-2.35 5.9834 5.9833 5.9833-5.9833 2.35 2.35-5.9833 5.9833z"
      fill="#fff"
    />
  </svg>
);

const Memo = memo(SvgIcoClose);
export default Memo;
