import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useCountry } from 'hooks';
import { QueryKeys } from 'queries';
import { countryActions } from 'store/country/country.slice';
import { SupportedCountry } from 'types/country.types';

import { Routes } from 'pages/routes.constants';

interface Props {
  countries: SupportedCountry[];
}

const CountrySelector = ({ countries }: Props) => {
  const { country } = useCountry();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleChange = (event: SelectChangeEvent) => {
    dispatch(countryActions.setCountry(event.target.value));
    queryClient.invalidateQueries(QueryKeys.user.byId(event.target.value));
    navigate(Routes.Root);
  };

  return (
    <Box textAlign="end">
      <Typography color="neutral01.100" variant="caption" mr={0.5}>
        <FormattedMessage id="country.selector.label" />
      </Typography>
      <Select
        variant="standard"
        value={country}
        onChange={handleChange}
        disableUnderline
        sx={{
          color: 'neutral01.100',
          '.MuiSelect-icon': {
            color: 'neutral01.100',
          },
          border: '1px solid',
          borderRadius: 1,
          px: 1.5,
        }}
      >
        {countries.map((country) => (
          <MenuItem key={country.countryCode} value={country.countryCode}>
            <Typography variant="caption">
              <FormattedMessage
                id={`header.location.${country.countryCode.toLocaleLowerCase()}`}
              />
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default CountrySelector;
