import { AxiosError } from 'axios';
import jwtDecode from 'jwt-decode';
import {
  all,
  put,
  SagaReturnType,
  select,
  takeLatest,
} from 'redux-saga/effects';

import config from 'config';

import { getAccessToken } from 'store/auth/auth.selectors';
import { authActions } from 'store/auth/auth.slice';
import { getCountry } from 'store/country/country.selectors';
import { countryActions } from 'store/country/country.slice';
import { AccessToken } from 'types/user.types';

import { systemActions } from './system.slice';

function* initApp() {
  try {
    /**
     * start your saga flows
     */

    const searchParams = window.location.search;
    const url = new URLSearchParams(window.location.hash.replace('#', '?'));
    let redirectUrl = config.AD_USER_FLOW_URL;
    let redirectSignupUrl = config.AD_SIGNUP_FLOW_URL;

    const signUp = url.get('l') === 'u';

    //Get access token from redux store
    const accessTokenStore: SagaReturnType<typeof getAccessToken> =
      yield select(getAccessToken);

    const token = url.get('access_token') ?? accessTokenStore ?? '';

    if (token) {
      //Put access token in redux store
      yield put(authActions.SET_ACCESS_TOKEN(token));
    }

    if (searchParams && searchParams.length > 3) {
      redirectUrl += '&' + searchParams.substring(1);
    }

    // Check expiration of access token
    if (token !== '') {
      const decodedToken = jwtDecode<AccessToken>(token ?? '');
      const location: SagaReturnType<typeof getCountry> = yield select(
        getCountry,
      );

      if (!location && decodedToken.country) {
        yield put(countryActions.setCountry(decodedToken.country));
      }

      const currentTimestamp = ~~(Date.now() / 1000);
      if (!decodedToken.exp || decodedToken.exp <= currentTimestamp) {
        if (signUp) {
          window.location.href = redirectSignupUrl + '&local=signup';
        } else {
          window.location.href = redirectUrl;
        }
      }
    } else {
      // Redirect to AD user flow
      if (signUp) {
        window.location.href = redirectSignupUrl + '&local=signup';
      } else {
        window.location.href = redirectUrl;
      }
    }

    /**
     * system is ready -> time to render UI!
     */
    yield put(systemActions.SYSTEM_READY());
  } catch (e: unknown) {
    yield put(systemActions.SYSTEM_FAIL(e as AxiosError<unknown>));
  }
}

export default function* () {
  yield all([takeLatest(systemActions.SYSTEM_START, initApp)]);
}
