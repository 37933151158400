import { AxiosInstance } from 'axios';

import countryApi from './country.api';
import pointshopApi from './pointshop.api';
import userApi from './user.api';

interface Options {
  api: AxiosInstance;
}

class RestApiService {
  public api: AxiosInstance;

  constructor(options: Options) {
    this.api = options.api;
  }

  // USER
  public getUser = userApi.getUser;
  public updateUser = userApi.updateUser;
  public getReferralCode = userApi.getReferralCode;
  public createUserOnComo = userApi.createUserOnComo;

  // POINTSHOP
  public getPointShopItems = pointshopApi.getPointShopItems;
  public buyPointShopItem = pointshopApi.buyPointShopItem;

  // COUNTRY
  public getSupportedCountries = countryApi.getSupportedCountries;
}

export default RestApiService;
