import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoGift = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
    <g clipRule="evenodd" fill="currentColor" fillRule="evenodd">
      <path d="m1.5 6c0-.27614.22386-.5.5-.5h10 10c.2761 0 .5.22386.5.5v3c0 .27614-.2239.5-.5.5h-1.5v12.5c0 .2761-.2239.5-.5.5h-8-8c-.27614 0-.5-.2239-.5-.5v-12.5h-1.5c-.27614 0-.5-.22386-.5-.5zm11 15.5h7v-12h-7zm-1-12v12h-7v-12zm1-1h7.5 1.5v-2h-9zm-1-2v2h-7.5-1.5v-2z" />
      <path d="m9.85782 2.51936c-.33022-.05846-.55864.02052-.68832.1502-.12964.12962-.20867.35803-.1502.68829.05812.3283.24912.70889.58158 1.04135s.71302.52346 1.04132.58158c.3302.05846.5586-.02053.6883-.15021s.2087-.35809.1502-.68831c-.0581-.32829-.2491-.70886-.5816-1.04132-.3324-.33247-.713-.52347-1.04128-.58158zm.17428-.98469c.5537.09801 1.1146.39959 1.5741.85916.4596.45957.7612 1.02047.8592 1.5741.0977.5517-.0037 1.14564-.4278 1.56975s-1.018.52545-1.5697.42779c-.55366-.09802-1.11456-.3996-1.57413-.85916-.45957-.45958-.76115-1.02051-.85916-1.57415-.09766-.55167.00366-1.14563.42778-1.56971m1.56971-.42778c-.55164-.09767-1.1456.0037-1.56971.42778z" />
      <path d="m13.1009 3.10079c.3324-.33244.713-.52344 1.0413-.58155zm0 0c-.3324.33247-.5235.71306-.5816 1.04136-.0585.33023.0205.55863.1502.6883.1297.12968.3581.20867.6883.1502.3283-.05811.7089-.24911 1.0413-.58155.3325-.33247.5235-.71309.5816-1.0414.0585-.33026-.0205-.55863-.1501-.68823-.1297-.12968-.3581-.2087-.6884-.15023m1.3955-.55694c-.4242-.42409-1.0181-.52542-1.5698-.42775-.5537.098-1.1146.39958-1.5742.85916-.4595.45956-.7611 1.02047-.8591 1.57409-.0977.55168.0036 1.14564.4278 1.56976.4241.42411 1.018.52545 1.5697.42778.5537-.098 1.1146-.39958 1.5741-.85916m0 0c.4596-.45956.7612-1.02049.8592-1.57411.0977-.55167-.0036-1.14568-.4277-1.56977" />
    </g>
  </svg>
);

const Memo = memo(SvgIcoGift);
export default Memo;
