import { Divider, Stack, Typography, useTheme } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { BottomCardDrawer } from 'components/@common';

interface Props {
  phoneNumber: string;
  isOpen: boolean;
  onCloseDrawer: () => void;
}

const QRDrawer = ({ phoneNumber, isOpen, onCloseDrawer }: Props) => {
  const theme = useTheme();
  return (
    <>
      <BottomCardDrawer isOpen={isOpen} onCloseDrawer={onCloseDrawer}>
        <Stack pt={5} alignItems="center" textAlign="center" px={7}>
          <QRCodeSVG
            value={phoneNumber}
            bgColor={theme.palette.global03[400]}
            fgColor={theme.palette.global02[400]}
            width={146.5}
            height={146.5}
          />
          <Typography pt={3} variant="h6">
            <FormattedMessage id="header.drawer.title" />
          </Typography>
        </Stack>
        <Divider variant="middle" />
        <Stack px={2}>
          <Typography variant="h6" gutterBottom>
            <FormattedMessage id="header.drawer.subtitle" />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage
              id="header.drawer.description"
              values={{ phoneNumber }}
            />
          </Typography>
        </Stack>
      </BottomCardDrawer>
    </>
  );
};

export default QRDrawer;
