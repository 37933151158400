import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      clipRule="evenodd"
      d="m12 3.5c-4.69445 0-8.5 3.80556-8.5 8.5 0 4.6944 3.80555 8.5 8.5 8.5 4.6944 0 8.5-3.8056 8.5-8.5 0-4.69444-3.8056-8.5-8.5-8.5zm-9.5 8.5c0-5.24673 4.25327-9.5 9.5-9.5 5.2467 0 9.5 4.25327 9.5 9.5 0 5.2467-4.2533 9.5-9.5 9.5-5.24673 0-9.5-4.2533-9.5-9.5zm9.4617-2.68286c.2761 0 .5.22386.5.5v6.43406c0 .2761-.2239.5-.5.5s-.5-.2239-.5-.5v-6.43406c0-.27614.2239-.5.5-.5zm0-2.14478c-.2761 0-.5.22386-.5.5 0 .27615.2239.5.5.5h.0019c.2761 0 .5-.22385.5-.5 0-.27614-.2239-.5-.5-.5z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

const Memo = memo(SvgIcoInfo);
export default Memo;
