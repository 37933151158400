import { isProd } from 'utils/env.utils';

export interface ENV_CONFIG {
  NAME: string;
  VERSION: string;
  API_BASE: string;
  API_CLIENT_URL: string;
  API_SERVER_URL: string;
  API_SUBSCRIPTION_KEY: string;
  AD_USER_FLOW_URL: string;
  AD_SIGNUP_FLOW_URL: string;
  LPQ_COUNTRY_PAGE_URL: string;
  LPQ_PRIVACY_PAGE_URL: string;
  LPQ_FAQ_PAGE_URL: string;
  LPQ_TC_PAGE_URL: string;
  COMO_PASS_URL: string;
  PORT?: number;
  ENABLE_DEV_TOOLS: boolean;
  LOCAL_STORAGE_ID: string;
  DEFAULT_CURRENCY: string;
  DEFAULT_LOCALE: string;
  DEFAULT_LOCALE_DATE: string;
  SENTRY: {
    dsn: string;
    sampleRate: number;
    tracesSampleRate: number;
    environment?: string;
    release?: string;
  };
  ANALYTICS: {
    GTM_ID: string;
  };
}

const config: ENV_CONFIG = {
  // general
  NAME: process.env.REACT_APP_NAME || '',
  VERSION: process.env.REACT_APP_VERSION || '',

  // api
  API_BASE: process.env.REACT_APP_API_BASE || '',
  API_CLIENT_URL: process.env.REACT_APP_API_CLIENT_URL || '',
  API_SERVER_URL: process.env.REACT_APP_API_SERVER_URL || '',
  API_SUBSCRIPTION_KEY: process.env.REACT_APP_API_SUBSCRIPTION_KEY || '',
  AD_USER_FLOW_URL: process.env.REACT_APP_AD_USER_FLOW_URL || '',
  AD_SIGNUP_FLOW_URL: process.env.REACT_APP_AD_SIGNUP_FLOW_URL || '',
  LPQ_COUNTRY_PAGE_URL: process.env.REACT_APP_LPQ_COUNTRY_PAGE_URL || '',
  LPQ_FAQ_PAGE_URL: process.env.REACT_APP_LPQ_FAQ_PAGE_URL || '',
  LPQ_TC_PAGE_URL: process.env.REACT_APP_LPQ_TC_PAGE_URL || '',
  LPQ_PRIVACY_PAGE_URL: process.env.REACT_APP_LPQ_PRIVACY_PAGE_URL || '',
  COMO_PASS_URL: process.env.REACT_APP_COMO_PASS_URL || '',

  // analytics
  ANALYTICS: {
    GTM_ID: process.env.REACT_APP_ANALYTICS_GTM_ID || '',
  },

  // error tracking
  SENTRY: {
    dsn: 'https://13d7307f773949b99e72dc9a8e6b8b16@o190235.ingest.sentry.io/6309095',
    environment: process.env.REACT_APP_BUILD_ENV,
    sampleRate: isProd() ? 0.15 : 1,
    tracesSampleRate: isProd() ? 0.15 : 1,
    release: isProd()
      ? `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`
      : `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_BUILD_ENV}`,
  },

  // dev
  PORT: Number(process.env.REACT_APP_PORT ?? 3000),
  ENABLE_DEV_TOOLS: Boolean(process.env.REACT_APP_ENABLE_DEV_TOOLS ?? true),
  LOCAL_STORAGE_ID: process.env.REACT_APP_LOCAL_STORAGE_ID ?? '',
  DEFAULT_CURRENCY: process.env.REACT_APP_DEFAULT_CURRENCY ?? 'EUR',
  DEFAULT_LOCALE: process.env.REACT_APP_DEFAULT_LOCALE ?? 'en',
  DEFAULT_LOCALE_DATE: process.env.REACT_APP_DEFAULT_LOCALE_DATE ?? 'nl',
};

export default config;
