import { AppBar, Box, styled, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { IcoArrowLeft } from 'components/@icons';

interface Props {
  label: ReactNode;
}

const BackHeader = ({ label }: Props) => {
  const navigate = useNavigate();

  const handleRouteBack = () => {
    navigate(-1);
  };

  return (
    <Box height={48} mb={3}>
      <AppBar>
        <Box
          display="flex"
          position="fixed"
          justifyContent="center"
          bgcolor="global02.400"
          flexWrap="wrap"
          alignItems="center"
          width="100%"
          px={2}
          py={1}
        >
          <Box
            display="flex"
            alignItems="center"
            flexGrow={1}
            left={16}
            onClick={handleRouteBack}
            color="neutral01.100"
          >
            <IcoArrowLeft width={24} height={24} />
            <Typography pl={1} variant="h6" color="neutral01.100">
              <FormattedMessage id="profile.information.header.action" />
            </Typography>
          </Box>
          <Box flexGrow={2}>{label}</Box>
        </Box>
      </AppBar>
    </Box>
  );
};

export default styled(BackHeader)();
