import { Components } from '@mui/material';

import { baseTheme } from 'theme/base';

export const MuiButton: Components['MuiButton'] = {
  styleOverrides: {
    root: {
      boxShadow: 'none',
      backgroundColor: 'transparant',
      border: `1px solid ${baseTheme.palette.primary.main}`,
      borderRadius: 0,
      color: baseTheme.palette.text.primary,
      fontWeight: 600,
      padding: 5,
      textTransform: 'none',
    },
    contained: {
      '&:hover': {
        backgroundColor: baseTheme.palette.primary.dark,
        color: baseTheme.palette.text.primary,
        boxShadow: 'none',
      },
      color: baseTheme.palette.text.primary,
      width: '100%',
    },
    text: {
      border: 'none',
      color: baseTheme.palette.global01[400],
      backgroundColor: 'transparant',
    },
    sizeLarge: {
      fontSize: '1.25rem',
      letterSpacing: '0.029rem',
      fontWeight: 400,
      lineHeight: 2,
    },
    sizeSmall: {
      fontSize: '0.875rem',
      letterSpacing: '0.029rem',
      fontWeight: 400,
      lineHeight: 1.7,
    },
  },
};
