import { Components, Theme } from '@mui/material';

export const MuiBottomNavigation: Components<Theme>['MuiBottomNavigation'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: theme.palette.global02[400],
      color: theme.palette.neutral01[100],
    }),
  },
};
