import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';

import { useCountry } from 'hooks';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { ComoUserCreateParams, User } from 'types/user.types';

const createUserOnComo = async (params: ComoUserCreateParams) => {
  return await apiService.createUserOnComo(
    params.comoUserParams,
    params.country,
  );
};

export const useCreateComoUser = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { country } = useCountry();
  const intl = useIntl();
  const { mutate, isLoading } = useMutation(createUserOnComo, {
    onSuccess: (_, variables) => {
      const prevUser = queryClient.getQueryData<User>(
        QueryKeys.user.byId(country),
      );
      // Optimistically update to the new value
      queryClient.setQueryData(QueryKeys.user.byId(country), () => ({
        ...prevUser,
        ...variables.comoUserParams,
        consent: variables.comoUserParams?.termsOfUse ? 'yes' : 'no',
      }));
    },
    onError: (error: AxiosError) => {
      if (error.response?.status === 409) {
        enqueueSnackbar(
          intl.formatMessage({ id: 'create.como.user.already.exists.error' }),
          {
            variant: 'error',
          },
        );
      } else if (error.response?.status === 400) {
        enqueueSnackbar(
          intl.formatMessage({ id: 'create.como.general.error' }),
          {
            variant: 'error',
          },
        );
      }
    },
  });

  return { createComoUser: mutate, isLoading };
};
