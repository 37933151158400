import { Box, Typography } from '@mui/material';

interface Props {
  value?: number;
}

const Counter = ({ value = 0 }: Props) => {
  return (
    <Box
      bgcolor="global01.400"
      display="flex"
      justifyContent="center"
      alignItems="center"
      width={20}
      height={20}
      sx={{ borderRadius: '50%' }}
    >
      {/* specific typography for only this component */}
      <Typography
        fontSize="0.75rem"
        lineHeight={1.6}
        fontWeight={450}
        color="neutral01.800"
      >
        {value}
      </Typography>
    </Box>
  );
};

export default Counter;
