import { Box, Link as MuiLink } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import config from 'config';

import { appendCountryToUrl } from 'utils/helpers';

import { useCountry } from 'hooks';
import { useSupportedCountries } from 'queries';

import { Routes } from 'pages/routes.constants';

import { CountrySelector } from 'components/@common';
import { IcoInfo, IcoProfile } from 'components/@icons';

import { NavLink } from '../NavLink';

const DesktopNavigation = () => {
  const { data: countries } = useSupportedCountries();
  const { country } = useCountry();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      height={48}
      px={18}
      bgcolor="global02.400"
      alignItems="center"
      width="100%"
    >
      <Box component="img" alt="Le Pain Quotidien" src="/assets/logo_lpq.svg" />
      <Box display="flex">
        <NavLink
          to={Routes.Root}
          label={<FormattedMessage id="navigation.home" />}
          pr={3}
        />
        <NavLink
          to={Routes.Shop}
          label={<FormattedMessage id="navigation.points" />}
          pr={3}
        />
        <NavLink
          to={Routes.Vouchers}
          label={<FormattedMessage id="navigation.vouchers" />}
          pr={3}
        />
      </Box>
      <Box color="neutral01.100" display="flex" alignItems="center">
        <NavLink
          to={Routes.Profile}
          sx={{ cursor: 'pointer' }}
          label={<IcoProfile />}
          pr={1}
          display="flex"
        />
        <MuiLink
          pr={1}
          target="_blank"
          display="flex"
          href={appendCountryToUrl(config.LPQ_FAQ_PAGE_URL, country)}
          color="neutral01.100"
        >
          <IcoInfo />
        </MuiLink>
        {countries && <CountrySelector countries={countries} />}
      </Box>
    </Box>
  );
};

export default DesktopNavigation;
