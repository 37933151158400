import { AppBar, Box, useMediaQuery } from '@mui/material';
import React from 'react';

import { useSupportedCountries } from 'queries';

import { CountrySelector } from 'components/@common';
import { LogoTartineclub } from 'components/@icons';

const DefaultHeader = () => {
  const { data: countries } = useSupportedCountries();
  const matches = useMediaQuery('(max-width:387px)');

  return (
    <Box height={64}>
      <AppBar>
        <Box
          display="flex"
          justifyContent="space-between"
          bgcolor="global02.400"
          alignItems="center"
          width="100%"
          px={2}
          py={1}
          height={64}
          sx={{ transition: '0.2s' }}
        >
          <Box display="flex" flexDirection="column" width="100%">
            <div>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <LogoTartineclub width={matches ? 115 : 155} height={32} />
                {countries && <CountrySelector countries={countries} />}
              </Box>
            </div>
          </Box>
        </Box>
      </AppBar>
    </Box>
  );
};

export default DefaultHeader;
