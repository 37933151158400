import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import config from 'config';

import {
  appendCountryToUrl,
  getPhraseProps,
  PhraseKeyProp,
} from 'utils/helpers';

import { useCountry } from 'hooks';

interface Props {
  labelId: PhraseKeyProp;
}

const Action = ({ labelId }: Props) => {
  const { country } = useCountry();
  const handleRedirect = () => {
    window.open(
      appendCountryToUrl(config.LPQ_COUNTRY_PAGE_URL, country),
      '_blank',
    );
  };

  return (
    <Box display="flex" justifyContent="center" pb={2}>
      <Typography
        variant="body2"
        sx={{
          opacity: '60%',
          textDecoration: 'underline',
        }}
        onClick={handleRedirect}
      >
        <FormattedMessage {...getPhraseProps(labelId)} />
      </Typography>
    </Box>
  );
};

export default Action;
