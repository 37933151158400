import { PointShopItem, PointShopParams } from 'types/pointshop.types';

import RestApiService from './';

export default {
  getPointShopItems(this: RestApiService, country: string) {
    return this.api.get<PointShopItem[]>(`/como/${country}/users/shop-items`);
  },
  buyPointShopItem(
    this: RestApiService,
    params: PointShopParams,
    country: string,
  ) {
    return this.api.post(`/como/${country}/shop-items/buy`, params);
  },
};
