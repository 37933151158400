import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import authReducer, { authPersistConfig } from './auth/auth.slice';
import countryReducer, { countryPersistConfig } from './country/country.slice';
import intlReducer, { intlPersistConfig } from './intl/intl.slice';
import onboardingReducer, {
  onboardingPersistConfig,
} from './onboarding/onboarding.slice';
import systemReducer from './system/system.slice';

const reducers = {
  system: systemReducer,
  intl: persistReducer(authPersistConfig, intlReducer),
  auth: persistReducer(intlPersistConfig, authReducer),
  onboarding: persistReducer(onboardingPersistConfig, onboardingReducer),
  country: persistReducer(countryPersistConfig, countryReducer),
};

export const rootReducer = combineReducers(reducers);

export type RootReducer = typeof rootReducer;
export type Store = ReturnType<RootReducer>;

export default rootReducer;
