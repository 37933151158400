import { addDays } from 'date-fns';

import { PointShopItem } from 'types/pointshop.types';
import { User } from 'types/user.types';

import { useMedia } from './useMedia';

export const useHomeRequirements = (
  pointShopItems: PointShopItem[],
  user?: User,
) => {
  const { isMobile } = useMedia();

  const isNewUser = () => {
    if (user) {
      return new Date() < addDays(new Date(user.createdOn), 2);
    } else {
      return false;
    }
  };

  const almostThereItem = pointShopItems.find((item) => {
    if (user && !user?.items?.length) {
      if (item.price > user.points) {
        return item;
      }
    }
    return undefined;
  });

  const homePointShopItems = pointShopItems
    .filter((item) => {
      if (almostThereItem) {
        if (item.id !== almostThereItem.id) {
          return item;
        }
      } else {
        return item;
      }
      return null;
    })
    .slice(0, isMobile ? 4 : 6);

  const getWelcomeVoucher = () => {
    if (user?.items?.length) {
      return user?.items.reduce((prev, curr) => {
        if (prev.validUntil > curr.validUntil) {
          return curr;
        }
        return prev;
      });
    } else {
      return undefined;
    }
  };

  const getHomeVouchers = () => {
    const welcomVoucher = getWelcomeVoucher();
    if (isNewUser()) {
      return user?.items
        ?.filter((item) => {
          if (welcomVoucher)
            if (item.id !== welcomVoucher.id) {
              return item;
            }
          return null;
        })
        .slice(0, 2);
    }
    return user?.items?.slice(0, 2);
  };

  return {
    isNewUser,
    welcomeVoucher: getWelcomeVoucher(),
    almostThereItem,
    homePointShopItems,
    homeVouchers: getHomeVouchers(),
  };
};
