import { alpha, Components, Theme } from '@mui/material';

export const MuiBackdrop: Components<Theme>['MuiBackdrop'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: alpha(theme.palette.global02[400], 0.8),
      zIndex: 1100,
    }),
  },
};
