import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

import history from 'services/history';

import { routes } from './routes';

const url = new URLSearchParams(window.location.hash.replace('#', '?'));
if (url.get('error')) {
  history.push('/error');
}

const Pages = () => {
  const element = useRoutes(routes);

  return <Suspense fallback={null}>{element}</Suspense>;
};

export default Pages;
