import { Components, Theme } from '@mui/material';

export const MuiDrawer: Components<Theme>['MuiDrawer'] = {
  styleOverrides: {
    paper: ({ theme }) => ({
      borderRadius: '16px 16px 0 0',
      height: theme.mixins.drawerHeight,
    }),
  },
};
