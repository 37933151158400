enum Keys {
  User = 'user',
  Wallet = 'wallet',
  PointShopItems = 'pointShopItems',
  referralCode = 'referralCode',
  SupportedCoutries = 'supportedCountries',
}

export const QueryKeys = {
  user: {
    byId: (country: string) => [Keys.User, country],
    referralCode: () => [Keys.referralCode],
  },
  pointshop: {
    all: (country: string) => [Keys.PointShopItems, country],
  },
  supportedCountries: {
    all: () => [Keys.SupportedCoutries],
  },
};
