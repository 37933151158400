import React from 'react';

import { createRoute } from 'utils/router.utils';

import { Routes } from 'pages/routes.constants';

import Overview from './Overview';
import ProfileInformation from './ProfileInformation';

/**
 * use lazy imports to get code splitting out-of-the-box
 * you can use "webpackChunkName" to name this chunk
 */
// tslint:disable:space-in-parens
const componentPromise = import('./Profile');
const Component = React.lazy(() => componentPromise);
// tslint:enable

/**
 * custom params
 * - authenticated: if true > renders authenticated route > else > renders public route
 */
export default createRoute(Component, {
  path: Routes.Profile,
  authenticated: true,
  children: [ProfileInformation, Overview],
});
