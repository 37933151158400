export enum Routes {
  Root = '/',
  Error = '/error',
  Registration = '/registration',

  Shop = '/shop',
  Info = '/info',
  Vouchers = '/vouchers',

  // generic routes
  RedirectNotAuthorized = '/login',

  //Profile
  Profile = '/profile',
  ProfileInformation = '/profile/information',
}
