import { useMutation, useQueryClient } from 'react-query';

import { useCountry } from 'hooks';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { User, UserUpdateParams } from 'types/user.types';

const updateUser = async (params: UserUpdateParams) => {
  return await apiService.updateUser(params.userParams, params.country);
};

export const useUpdateUser = () => {
  const { country } = useCountry();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(updateUser, {
    onSuccess: (_, variables) => {
      const prevUser = queryClient.getQueryData<User>(
        QueryKeys.user.byId(country),
      );
      // Optimistically update to the new value
      queryClient.setQueryData(QueryKeys.user.byId(country), () => ({
        ...prevUser,
        ...variables.userParams,
      }));
    },
  });

  return { updateUser: mutate, isLoading };
};
