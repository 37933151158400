import type {
  ComoUserParams,
  ReferralCodeResponse,
  User,
  UserParams,
  UserUpdateResponse,
} from 'types/user.types';

import RestApiService from './';

export default {
  getUser(this: RestApiService, country: string) {
    return this.api.get<User>(`/como/${country}/memberDetails`);
  },
  updateUser(this: RestApiService, params: UserParams, country: string) {
    return this.api.put<UserUpdateResponse>(
      `como/${country}/memberDetails`,
      params,
    );
  },
  getReferralCode(this: RestApiService, country: string) {
    return this.api.get<ReferralCodeResponse>(
      `/como/${country}/users/referral`,
    );
  },
  createUserOnComo(
    this: RestApiService,
    params: ComoUserParams,
    country: string,
  ) {
    return this.api.post<UserUpdateResponse>(`como/${country}/users`, params);
  },
};
