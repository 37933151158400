import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { useCallback } from 'react';

type ErrorEventPayload = {
  errorCategory?: string | null;
  errorCode?: string | null;
  errorMessage?: string | null;
};

const defaultErrorPayload = {
  errorCategory: 'generic_error',
  errorCode: '',
  errorMessage: 'Generic error in react app',
};

// This hook is a "mirror" of the implementation in user_flow/templates/js/GTM.js
export const useAnalytics = () => {
  const sendDataToGTM = useGTMDispatch();

  const trackError = useCallback(
    ({
      errorCategory,
      errorCode,
      errorMessage,
    }: ErrorEventPayload = defaultErrorPayload) => {
      const payload = {
        event: 'error_logged',
        data: {
          error_category: errorCategory ?? defaultErrorPayload.errorCategory,
          error_code: errorCode ?? defaultErrorPayload.errorCode,
          error_message: errorMessage ?? defaultErrorPayload.errorMessage,
        },
      };

      sendDataToGTM(payload);
    },
    [sendDataToGTM],
  );

  return {
    trackError,
  };
};
