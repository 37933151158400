import config from 'config';

import RestApiService from './api';
import { createManager } from './api/axios';

// You can export here services, ES module exports ensure these will all be a single object
export const apiService = new RestApiService({
  api: createManager(
    {
      baseURL: config.API_BASE,
    },
    config.API_SUBSCRIPTION_KEY,
  ),
});
