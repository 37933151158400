import { createSlice } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

import config from 'config';

export type AuthState = {
  accessToken?: string;
};

export const authPersistConfig = {
  key: `${config.LOCAL_STORAGE_ID}.auth`,
  storage,
};

const INITIAL_STATE: AuthState = {
  accessToken: undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    SET_ACCESS_TOKEN: (state, { payload }) => {
      state.accessToken = payload;
    },
  },
});

export const { actions: authActions } = authSlice;

export default authSlice.reducer;
