import { useSelector } from 'react-redux';

import { useSupportedCountries } from 'queries';
import { getCountry } from 'store/country/country.selectors';

export const useCountry = () => {
  const country = useSelector(getCountry);
  const { data } = useSupportedCountries();

  const currentCountry = data?.filter(
    (item) => item.countryCode === country.toUpperCase(),
  );

  const languageOptions =
    currentCountry?.map((item) => item.languages)[0] ?? [];

  const appUrls = currentCountry?.map((item) => item.languages)[0];

  return {
    country,
    languageOptions,
    appUrls,
  };
};
