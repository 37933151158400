import { AppBar, Box, Collapse, Typography, useTheme } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useSupportedCountries, useUser } from 'queries';

import { CountrySelector } from 'components/@common';
import { IcoPoints, LogoTartineclub } from 'components/@icons';

import Header from './Header';

interface Props {
  collapsable?: boolean;
  onOpenDrawer: () => void;
}

const ExpandableHeader = ({ collapsable = true, onOpenDrawer }: Props) => {
  const theme = useTheme();
  const { data: countries } = useSupportedCountries();
  const { data: user, isFetching } = useUser();
  const [isExpanded, setIsExpanded] = useState(false);
  const [appbarHeight, setAppbarHeight] = useState(190);
  const appbarRef = useRef<HTMLDivElement>(null);

  const handleExpandHeader = () => {
    setIsExpanded(window.scrollY > 100);
  };

  useEffect(() => {
    if (window) {
      window.addEventListener('scroll', handleExpandHeader);
    }

    return () => window.removeEventListener('scroll', handleExpandHeader);
  }, []);

  useEffect(() => {
    if (user) {
      setAppbarHeight(appbarRef.current ? appbarRef.current.offsetHeight : 190);
    }
  }, [user]);

  return (
    <>
      <Box height={appbarHeight} />
      <AppBar sx={{ backgroundColor: 'global02.400' }} ref={appbarRef}>
        <Collapse collapsedSize={48} in={!isExpanded}>
          <Box
            display="flex"
            justifyContent="space-between"
            bgcolor="global02.400"
            width="100%"
            px={2}
            py={isExpanded || !collapsable ? 1 : 2}
          >
            <Box
              display={!isExpanded && collapsable ? 'flex' : 'none'}
              flexDirection="column"
              width="100%"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <LogoTartineclub width={155} height={32} />
                {countries && <CountrySelector countries={countries} />}
              </Box>
              {user && !isFetching && (
                <Box display="flex">
                  <Box
                    bgcolor="neutral01.100"
                    p={1}
                    mr={2}
                    width={80}
                    height={80}
                    onClick={onOpenDrawer}
                  >
                    <QRCodeSVG
                      value={user.phoneNumber ?? ''}
                      fgColor={theme.palette.global02[400]}
                      width={64}
                      height={64}
                    />
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center">
                      <IcoPoints height={40} width={40} />
                      <Typography pl={1} variant="h3" color="neutral01.100">
                        {user.points ?? 0}
                      </Typography>
                    </Box>
                    <Typography mt={1} variant="caption" color="neutral01.100">
                      <FormattedMessage id="header.description" />
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
            <Box
              display={isExpanded || !collapsable ? 'flex' : 'none'}
              width="100%"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header onOpenDrawer={onOpenDrawer} />
            </Box>
          </Box>
        </Collapse>
      </AppBar>
    </>
  );
};

export default ExpandableHeader;
