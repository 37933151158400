import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Paper,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import config from 'config';

import { appendCountryToUrl } from 'utils/helpers';

import { useCountry } from 'hooks';
import { useUser } from 'queries';

import { Routes } from 'pages/routes.constants';

import { Counter } from 'components/@common';
import {
  IcoGift,
  IcoHome,
  IcoInfo,
  IcoProfile,
  IcoShop,
} from 'components/@icons';

const MobileNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = useUser();
  const { country } = useCountry();

  const navigateToPage = (route: string) => {
    if (route === Routes.Info) {
      window.open(
        appendCountryToUrl(config.LPQ_FAQ_PAGE_URL, country),
        '_blank',
      );
    } else {
      navigate(generatePath(`${route}`));
    }
  };

  return (
    <>
      <Box height={56} />
      <Paper
        sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1 }}
        elevation={3}
      >
        {/* Added paper to have the navigation bar fixed at the bottom*/}
        <BottomNavigation
          showLabels
          value={location.pathname}
          onChange={(event, newValue) => {
            navigateToPage(newValue);
          }}
        >
          <BottomNavigationAction
            label={<FormattedMessage id="navigation.home" />}
            icon={<IcoHome width={24} height={24} />}
            value={Routes.Root}
          />
          <BottomNavigationAction
            label={<FormattedMessage id="navigation.points" />}
            icon={<IcoShop width={24} height={24} />}
            value={Routes.Shop}
          />
          <BottomNavigationAction
            label={<FormattedMessage id="navigation.vouchers" />}
            icon={
              <Box display="flex">
                <IcoGift width={24} height={24} />
                {!!data?.items?.length && (
                  <Box position="absolute" left={55}>
                    <Counter value={data.items.length} />
                  </Box>
                )}
              </Box>
            }
            value={Routes.Vouchers}
          />
          <BottomNavigationAction
            label={<FormattedMessage id="navigation.profile" />}
            icon={<IcoProfile width={24} height={24} />}
            value={Routes.Profile}
          />
          <BottomNavigationAction
            label={<FormattedMessage id="navigation.info" />}
            icon={<IcoInfo width={24} height={24} />}
            value={Routes.Info}
          />
        </BottomNavigation>
      </Paper>
    </>
  );
};

export default MobileNavigation;
