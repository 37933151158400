import { Link as MuiLink, LinkProps } from '@mui/material';
import React, { ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface Props {
  to: string;
  label: ReactNode;
}

const NavLink = ({ to, label, ...rest }: Props & LinkProps) => {
  const { pathname: currentPath } = useLocation();

  return (
    <MuiLink
      component={Link}
      to={to}
      {...rest}
      color={currentPath === to ? 'global01.400' : 'neutral01.100'}
    >
      {label}
    </MuiLink>
  );
};

export default NavLink;
