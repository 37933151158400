import { Container, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { useMedia } from 'hooks';

import BackHeader from 'components/@header/Header/BackHeader';

interface Props {
  children: ReactNode;
}

const BackContainer = ({ children }: Props) => {
  const { isMobile } = useMedia();

  return (
    <>
      {isMobile && (
        <BackHeader
          label={
            <Typography variant="h6" color="neutral01.100">
              <FormattedMessage id="profile.information.header.title" />
            </Typography>
          }
        />
      )}

      <Container>{children}</Container>
    </>
  );
};

export default BackContainer;
