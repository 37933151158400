import { useQuery } from 'react-query';

import { useCountry } from 'hooks';
import { QueryKeys } from 'queries';
import { StaleTime } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { ReferralCodeResponse } from 'types/user.types';

const fetchReferralCode = async (country: string) => {
  const { data: response } = await apiService.getReferralCode(country);
  return response;
};

export const useReferralCode = (
  config: UseCommonQueryOptions<ReferralCodeResponse> = {},
) => {
  const { country } = useCountry();
  const { data, status, isLoading, error } = useQuery(
    QueryKeys.user.referralCode(),
    () => fetchReferralCode(country),
    {
      ...config,
      staleTime: config?.staleTime ?? StaleTime.FOREVER,
    },
  );

  return {
    data: data?.referralCode,
    status,
    isLoading,
    error,
  };
};
