import { buttonClasses, Components } from '@mui/material';

export const MuiButtonBase: Components['MuiButtonBase'] = {
  defaultProps: {
    disableRipple: true,
    disableTouchRipple: true,
  },
  styleOverrides: {
    root: {
      [`&.${buttonClasses.disabled}`]: {
        border: 0,
      },
    },
  },
};
