import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgIcoArrowLeft);
export default Memo;
