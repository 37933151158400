import { Box, BoxProps, CircularProgress } from '@mui/material';

const LoadingState = ({ ...rest }: BoxProps) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    flexGrow="1"
    height="100%"
    {...rest}
  >
    <CircularProgress />
  </Box>
);

export default LoadingState;
