import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoPoints = (props: SVGProps<SVGSVGElement>) => (
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 141.73 141.73"
    enableBackground="new 0 0 141.73 141.73"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <style>
      {
        '.st0{fill:#FFFFFF;}\n\t.st1{fill:#FFC629;}\n\t.st2{fill:none;stroke:#000000;stroke-width:2;stroke-miterlimit:10;}'
      }
    </style>
    <g id="Calque_2">
      <path
        className="st0"
        d="M79.96,18.14c0,0-19.97-6.04-33.11-0.61S16.33,27.32,14.18,44.1c0,0,0.26,10.46,8.82,17.7l0.69,10.25 l1.59,48.01v6.21h88.8l2.03-5.04V70.88v-8.24l4.37-4.66c0,0,5.54-8.75,3.79-15.45s-8.27-20.04-23.67-22.26S79.96,18.14,79.96,18.14 z"
      />
    </g>
    <g id="Calque_1">
      <path
        className="st1"
        d="M114.08,24.22c0,0-4.94,34.52-26.4,43.23S56.06,79.33,55.74,90.43s6.86,31.65,37.22,34.54l-49.59,1.31 l-15.76-1.31c0,0-2.04,0.99-2.34-10.43c-0.3-11.42,0-37.76,0-37.76s-0.3-14.28-3.79-17.45s-8.57-9.84-7.3-15.23 s2.86-14.4,16.5-19.89s18.86-9.25,26.51-7.39s24.09-2.26,24.09-2.26L114.08,24.22z"
      />
      <path d="M125.47,37.45c-2.23-5.7-6.39-9.89-11.39-13.23c-9.92-6.62-21.04-9.22-32.81-9.66c-5.54-0.21-11.03,0.63-16.61,0.18 c-9.64-0.79-19.13,0.51-28.25,3.92c-7.27,2.72-13.83,6.55-18.74,12.74c-4.29,5.41-6.1,11.5-4.54,18.36 c1.19,5.21,4.16,9.36,8.08,12.88c0.68,0.61,0.95,1.22,0.95,2.13c-0.02,20.91-0.02,41.81-0.02,62.72v0.01c0,0.74,0.6,1.34,1.34,1.34 h90.44c1.89,0,3.43-1.53,3.43-3.43V72.74c0-3.04,0.11-5.12,0.07-8.05c-0.01-0.82,0.21-1.39,0.83-1.95c1.03-0.92,2.03-1.9,2.92-2.96 C126.75,53.13,128.7,45.73,125.47,37.45z M95.49,60.73c-0.7,0.59-0.99,1.18-0.99,2.1c0.03,20.19,0.02,40.38,0.02,60.57v0.02 c0,0.86-0.7,1.56-1.56,1.56H27.61c-0.87,0-1.58-0.71-1.58-1.58l0,0c0-20.15-0.01-40.3,0.02-60.45c0-1.02-0.31-1.66-1.09-2.3 c-3.81-3.14-6.82-6.88-7.96-11.8c-1.4-6.04,0.48-11.27,4.37-15.85c4.58-5.39,10.57-8.65,17.13-10.93 c8.52-2.96,17.32-3.98,26.32-3.38c8.79,0.58,17.19,2.58,24.96,6.88c3.71,2.05,7.05,4.58,9.72,7.91c6.21,7.72,5.96,16.7-0.67,24.06 C97.81,58.67,96.67,59.74,95.49,60.73z M121.86,51.16c-1.48,3.93-4.16,6.99-7.37,9.6c-0.68,0.55-0.96,1.12-0.95,2 c0.05,3.25,0.02,6.51,0.02,9.76c0,0.14-0.01,0.18-0.01,0.21l0,0c0,0.01,0,0.01,0,0.02c-0.01,0.03-0.01,0.06,0,0.23v49.59 c0,1.32-1.07,2.4-2.4,2.4H99.04c-0.35,0-0.63-0.28-0.63-0.63l0,0c0-20.03,0-40.07,0.03-60.1c0-0.43,0.31-0.95,0.62-1.29 c1.02-1.11,2.16-2.11,3.15-3.24c6.64-7.66,7.61-17.24,2.55-25.75c-3.04-5.11-7.47-8.8-12.59-11.67c-1.22-0.68-2.46-1.3-3.72-1.9 c-0.67-0.32-0.37-1.34,0.37-1.24c5.56,0.75,11,2.15,16.21,4.46c4.98,2.21,9.53,5.05,13.1,9.25 C122.68,38.25,124.44,44.3,121.86,51.16z" />
      <path
        className="st2"
        d="M88.46,58.82c-0.56,0.47-0.79,0.94-0.79,1.68c0.02,16.16,0.02,32.32,0.02,48.48v9.39H32.87v-9.39 c0-16.13-0.01-32.26,0.02-48.39c0-0.82-0.25-1.33-0.87-1.84c-3.05-2.52-5.46-5.51-6.37-9.45c-1.12-4.84,0.38-9.02,3.5-12.69 c3.66-4.31,8.46-6.92,13.71-8.75c6.82-2.37,13.86-3.19,21.07-2.71c7.04,0.47,13.76,2.07,19.98,5.51c2.97,1.64,5.64,3.67,7.78,6.33 c4.97,6.18,4.77,13.37-0.53,19.26C90.32,57.17,89.41,58.02,88.46,58.82z"
      />
      <g>
        <path d="M56.16,75.13l-4.77,0.39c-0.49,0-0.88-0.29-0.88-0.88l-0.19-3.02c0-0.49,0.19-0.68,0.68-1.07l7.21-4.58h4.97 c0.49,0,0.88,0.39,0.88,0.88v27.07c0,0.49-0.39,0.88-0.88,0.88h-6.04c-0.58,0-0.97-0.39-0.97-0.88L56.16,75.13L56.16,75.13z" />
      </g>
    </g>
  </svg>
);

const Memo = memo(SvgIcoPoints);
export default Memo;
