// comment out locales that should not be supported
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/be';

import { createIntl, createIntlCache, IntlShape } from 'react-intl';

import config from 'config';

import MESSAGES_EN from './translations/en.json';
import MESSAGES_FR from './translations/fr.json';
import MESSAGES_NL from './translations/nl.json';

const { DEFAULT_LOCALE, DEFAULT_LOCALE_DATE } = config;

const locales = ['en', 'fr', 'nl'];

const intlCache = createIntlCache();

const intl: { [key: string]: IntlShape } = {
  en: createIntl({ locale: 'en-GB', messages: MESSAGES_EN }, intlCache),
  fr: createIntl({ locale: 'fr-BE', messages: MESSAGES_FR }, intlCache),
  nl: createIntl({ locale: 'nl-BE', messages: MESSAGES_NL }, intlCache),
  //Will be added later
  //de: createIntl({ locale: 'de-DE', messages: MESSAGES_DE }, intlCache),
};

const formatBrowserLocale = (locale: string) => {
  if (locale) {
    return locale.split('-')[0].toLowerCase();
  } else {
    return DEFAULT_LOCALE;
  }
};

const isValidLocale = (locale: string) => locales.includes(locale);

export {
  DEFAULT_LOCALE,
  DEFAULT_LOCALE_DATE,
  formatBrowserLocale,
  intl,
  isValidLocale,
  locales,
};
