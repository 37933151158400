import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoShop = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.37419 2.5C3.15278 2.5 2.95774 2.64562 2.89481 2.8579L1.52062 7.49381C1.50694 7.53994 1.5 7.5878 1.5 7.63591C1.5 8.90001 2.34667 9.94627 3.5 10.3363V21C3.5 21.2761 3.72386 21.5 4 21.5H20C20.2761 21.5 20.5 21.2761 20.5 21V10.3361C21.6534 9.94582 22.5 8.89888 22.5 7.63591C22.5 7.58789 22.4931 7.54012 22.4795 7.49407L21.108 2.85816C21.0452 2.64575 20.8501 2.5 20.6286 2.5H3.37419ZM19.5 10.5C18.4696 10.4999 17.5426 9.99735 17.0005 9.22095C16.4583 9.99713 15.5314 10.5 14.501 10.5C13.4696 10.5 12.5417 9.99658 11.9998 9.21898C11.4578 9.99627 10.5302 10.5 9.49897 10.5C8.4684 10.5 7.54124 9.99744 6.99911 9.22095C6.45701 9.99704 5.53026 10.4999 4.5 10.5V20.5H12.5V13C12.5 12.7239 12.7239 12.5 13 12.5H18C18.2761 12.5 18.5 12.7239 18.5 13V20.5H19.5V10.5ZM17.5 20.5V13.5H13.5V20.5H17.5ZM9.49897 9.5C8.3686 9.5 7.49931 8.63985 7.49931 7.63591C7.49931 7.35977 7.27546 7.13591 6.99931 7.13591C6.72317 7.13591 6.49931 7.35977 6.49931 7.63591C6.49931 8.63873 5.62984 9.5 4.49966 9.5C3.39492 9.5 2.53955 8.67841 2.50133 7.70396L3.74749 3.5H20.2551L21.4987 7.70382C21.4605 8.6773 20.6049 9.5 19.5003 9.5C18.37 9.5 17.5007 8.63985 17.5007 7.63591C17.5007 7.35977 17.2768 7.13591 17.0007 7.13591C16.7245 7.13591 16.5007 7.35977 16.5007 7.63591C16.5007 8.63873 15.6312 9.5 14.501 9.5C13.3707 9.5 12.5014 8.63985 12.5014 7.63591C12.5014 7.35977 12.2775 7.13591 12.0014 7.13591H11.9986C11.7225 7.13591 11.4986 7.35977 11.4986 7.63591C11.4986 8.63873 10.6292 9.5 9.49897 9.5ZM5.5 13C5.5 12.7239 5.72386 12.5 6 12.5H11C11.2761 12.5 11.5 12.7239 11.5 13V18C11.5 18.2761 11.2761 18.5 11 18.5H6C5.72386 18.5 5.5 18.2761 5.5 18V13ZM6.5 13.5V17.5H10.5V13.5H6.5Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgIcoShop);
export default Memo;
