import { createTheme } from '@mui/material/styles';

/**
 *   Setup base theme allows to be imported at other places (ex, palette, typography, spacing,...)
 */
export const baseTheme = createTheme({
  palette: {
    global01: {
      100: '#FFF8E0',
      200: '#FEE180',
      300: '#FFD33D',
      400: '#FDC400',
      500: '#E8B200',
      600: '#BF9300',
      700: '#997500',
    },
    global02: {
      300: '#40524F',
      400: '#293735',
      500: '#202B2A',
    },
    global03: {
      300: '#FFFBF7',
      400: '#FBF5EE',
      500: '#E8DED3',
    },
    neutral01: {
      100: '#FFFFFF',
      200: '#F2F2F2',
      300: '#E6E6E6',
      400: '#CCCCCC',
      500: '#999999',
      600: '#666666',
      700: '#333333',
      800: '#000000',
    },
    status: {
      error01: {
        100: '#FAEBEB',
        200: '#EB5757',
        300: '#D13838',
        400: '#B02727',
        500: '#541616',
      },
      success01: {
        100: '#E9F7EF',
        200: '#45D682',
        300: '#27AE60',
        400: '#14703B',
        500: '#104626',
      },
      success02: {
        100: '#FDF0E6',
        200: '#FF9800',
        300: '#ED6C02',
        400: '#E65100',
        500: '#5F2B01',
      },
    },
    primary: {
      main: '#fdc402',
      dark: '#e7b900',
    },
    secondary: {
      main: '#293735cf',
    },
    background: {
      default: '#ffffff',
      paper: '#fbf5ee',
    },
    text: {
      primary: '#000000',
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: 'Brandon',
    h1: {
      fontWeight: 420,
      fontSize: '3.125rem',
      letterSpacing: '-0.094rem',
      lineHeight: 1.2,
    },
    h2: {
      fontWeight: 400,
      fontSize: '2.625rem',
      letterSpacing: '-0.031rem',
      lineHeight: 0.9,
    },
    h3: {
      fontWeight: 400,
      fontSize: '2.1875rem',
      letterSpacing: 0,
      lineHeight: 0.9,
    },
    h4: {
      fontWeight: 420,
      fontSize: '1.8125rem',
      letterSpacing: '0.016rem',
      lineHeight: 0.9,
    },
    h5: {
      fontWeight: 400,
      fontSize: '1.5rem',
      letterSpacing: 0,
      lineHeight: 1.3,
    },
    h6: {
      fontWeight: 420,
      fontSize: '1.25rem',
      letterSpacing: '0.009rem',
      lineHeight: 1.6,
    },
    button: {
      fontWeight: 400,
      fontSize: '1.25rem',
      letterSpacing: '0.025rem',
      lineHeight: 1.6,
    },
    caption: {
      fontWeight: 400,
      fontSize: '0.75rem',
      letterSpacing: '0.025rem',
      lineHeight: 1.6,
    },
  },
  mixins: {
    drawerHeight: '50%',
  },
});
