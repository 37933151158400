/**
 * import routes as following: "import [name] from './[name]'"
 * & add it to the array of routes
 */
import { RouteObject } from 'react-router-dom';

import { Layout } from 'components/@layout';

import error from './error';
import home from './home';
import profile from './profile';
import registration from './registration';
import shop from './shop';
import vouchers from './vouchers';

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <Layout />,
    children: [home, shop, vouchers, profile, registration],
  },
  error,
];
