import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { isDev } from 'utils/env.utils';

import { gtmParams } from 'services/analytics';
import IntlSelectorProvider from 'services/i18n';
import { NotificationProvider } from 'services/notifications';
import { queryClient } from 'services/react-query';
import storeConfig from 'store';
import theme from 'theme';

import Pages from 'pages/Pages';

import { RootBoundary } from 'components/@boundaries';
import { LoadingState } from 'components/@states';

const App: React.VFC = () => {
  return (
    <RootBoundary>
      <GTMProvider state={gtmParams}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <ReduxProvider store={storeConfig.store}>
              <PersistGate
                loading={<LoadingState />}
                persistor={storeConfig.persistor}
                onBeforeLift={storeConfig.initApp}
              >
                <IntlSelectorProvider>
                  <HelmetProvider>
                    <BrowserRouter>
                      <NotificationProvider>
                        <Pages />
                      </NotificationProvider>
                    </BrowserRouter>
                  </HelmetProvider>
                </IntlSelectorProvider>
              </PersistGate>
            </ReduxProvider>
          </ThemeProvider>
          {isDev() && (
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          )}
        </QueryClientProvider>
      </GTMProvider>
    </RootBoundary>
  );
};

export default App;
