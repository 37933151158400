import { Box, Stack, Typography, useTheme } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import { FormattedMessage } from 'react-intl';

import { useUser } from 'queries';

import { IcoPoints } from 'components/@icons';

export interface Props {
  onOpenDrawer: () => void;
}

const Header = ({ onOpenDrawer }: Props) => {
  const { data: user } = useUser();
  const theme = useTheme();

  return (
    <>
      <Stack direction="row" alignItems="center" onClick={onOpenDrawer}>
        <Box bgcolor="neutral01.100" p={0.5} mr={1} width={32} height={32}>
          <QRCodeSVG
            value={user?.phoneNumber ?? ''}
            fgColor={theme.palette.global02[400]}
            width={24}
            height={24}
          />
        </Box>
        <Typography variant="caption" color="neutral01.100">
          <FormattedMessage id="header.small.qrcode.action" />
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center">
        <IcoPoints height={24} width={24} />
        <Typography pl={1} variant="h6" color="neutral01.100">
          {user?.points}
        </Typography>
      </Stack>
    </>
  );
};

export default Header;
