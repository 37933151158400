import { Components, Theme } from '@mui/material';

export const MuiSelect: Components<Theme>['MuiSelect'] = {
  defaultProps: {
    MenuProps: {
      MenuListProps: {
        sx: {
          backgroundColor: 'white',
        },
      },
    },
  },
};
